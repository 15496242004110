import axios from "axios";

const API_URL = process.env.VUE_APP_STORAGE_API_BASE_URL;

export default {
    createShowFileURL(fileID) {
        return `${API_URL}/files/${fileID}/:show`;
    },

    async upload(token, file) {
        const response = await axios.post(API_URL + `/files/:upload`, {
            "file": file,
        }, {
            headers: {
                "Token": token,
                "content-type": "multipart/form-data",
            },
        });

        return response.data
    },

    async show(file_id) {
        return await axios.get(API_URL + `/files/${file_id}/:show`, {
            headers: {
                "content-type": "multipart/form-data",
            },
            responseType: "blob",
        })
    },
};
