<template>
    <div class="container-fluid mt-4">
        <div class="row">
            <div class="col-12">
                <div id="basic-info" class="card mt-4">
                    <div class="card-header">
                        <h5>Добавить питомца</h5>
                    </div>

                    <FamilyPetEditForm :_pet="pet_blank" mode="create"></FamilyPetEditForm>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FamilyPetEditForm from "@/views/components/family/pet/EditForm.vue";

export default {
  name: "Profile",

  components: {
    FamilyPetEditForm,
  },

  data() {
    return {
      pet_blank: this.genPetBlank(),
    }
  },

  methods: {
    genPetBlank() {
      let birthDate = new Date()
      birthDate.setFullYear(birthDate.getFullYear() - 1)
      let bDateStr = birthDate.getFullYear() + "-"
      bDateStr += ((birthDate.getMonth() < 10) ? ("0" + birthDate.getMonth()) : birthDate.getMonth()) + "-"
      bDateStr += "01"

      return {
        cheap_number: "",
        name: "",
        email: "",
        birth_date: bDateStr,
        weight: 10.0,
        breed_id: 5,
        family_id: this.$route.params.family_id,
        gender: 0,
      };
    },
  },
}
</script>